.complexes-map-canvas{
  width:100%;
  height: 300px;
}

.infowindow-wrapper{
  img{
    max-width: 150px !important;
  }

  .infowindow-title{
    display:block;
    color: #fff;
    background: @brand-color;
    padding:5px;
    text-align: center;
    font-weight: bold;
  }
}

.property-infowindow-image{
    float:left;
    margin-right: 10px;
  }
  .property-infowindow-content{
    float:right;
  }
