.divider-stonepark{
  p{
    color: #fff;
    opacity:.8;
  }
}
.divider-box{
  padding: 20px 0 20px;
}

.divider-simple{
  padding: 20px 0 20px;
}

.divider-grey{
 background: #EDF1F2;
}

.divider-grey0{
  background: #EDF1F2;
  padding:0;
}

.divider-redcouch{
  padding: 20px 0;
  background-image: url('../images/redcouch.jpg');
  background-position: 50% 50%;
  background-size: cover;
  margin:0;
}

.divider-testimonials{
  padding: 20px 0;
  background-image: url('../images/blackfamily.jpg');
  background-position: 50% 50%;
  background-size: cover;
  margin:0;

  h1,h2,h3,p{
    color: #fff;
  }

}

.divider-black{
  padding: 20px 0;
  background: #1C1E1E;
  h1,h2,h3, a{
    color:#fff;
  }
}

.divider-firma{
  padding:0;
  .row,.col-sm-6{
    padding:0;
    margin:0;
  }
}


.divider-ansamblu{
  padding:0;
  background: #656A7E;
  color: #fff;
  .row,.col-sm-6{
    padding:0;
    margin:0;
  }
}

.ansamblu-wrapper{
  padding: 20px;
  text-align:right;
  max-width: 600px;
  float:right;
  h1,h2,h3{
    text-align:right;
  }

  .ansamblu-button{
    font-size:16px;
    display:inline-block;
    margin-left:30px;
    margin-top:30px;
    background: @brand-color;
    color: #fff;
    padding:10px 20px;
    text-transform: uppercase;

  }
}

.firma-wrapper{
  padding: 20px;
  text-align:right;
  max-width: 600px;
  float:right;
  h1,h2,h3{
    text-align:right;
  }

  .firma-link{
    font-size:16px;
    display:inline-block;
    margin-left:30px;
  }
}


.container-title{
  text-align:center;
  position: relative;
  padding-bottom: 0.63em;
 margin-bottom: 50px;

  &:before{
    background-color: rgba(218,221,221,0.5);
    content: '';
    height: 1px;
    width: 360px;
    bottom: 0;
    position: absolute;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
  }

  &:after{
    background-color: @brand-color;
    content: '';
    height: 3px;
    width: 84px;
    bottom: -2px;
    position: absolute;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
  }
}

.container-title.title-right{
  &:before{
    content: '';
    height: 1px;
    width: 222px;
    bottom: 0;
    position: absolute;
    right: 0;
    left: auto;
    display: block;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
  }

  &:after{
    background-color: @brand-color;
    content: '';
    height: 3px;
    width: 84px;
    bottom: -2px;
    position: absolute;
    left: auto;
    right: 0;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
    display: block;
  }
}