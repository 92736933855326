.module-btn-color-red{
  background: #EB2645;
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;

  &:hover{
    background: fade(#EB2645, 95%);
    color: #fff;
    text-decoration: none;

  }
}


.module-btn-color-black{
 border:1px solid #fff;
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;

  &:hover{
    opacity:.8;
    color: #fff;
    text-decoration: none;

  }
}

.module-btn{
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;

  &:hover{
    color: #fff;
    text-decoration: none;
  }
}

.module-btn-color-orange{
  background: orange;
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;

  &:hover{
    background: fade(orange, 95%);
    color: #fff;
    text-decoration: none;

  }
}

.module-btn-color-blue{
  background: #62a8ea;

  &:hover {
    background: fade(#62a8ea, 95%);
  }
}

.module-stonepark-button{
  display:inline-block;
  margin-left:10px;
}