.text-icon-bg{
  background: #fff;
}


a.text-icon-link{
  text-decoration: none !important;

  &:hover{
    .text-icon{
    background: @brand-color;
      h3, p, i{
        color: #fff;
      }
    }
  }
}
.text-icon {
  display:block;
  position:relative;
  overflow: visible;
  //margin-bottom: 60px;
  background: #fff;
  padding: 25px 15px;
  transition: all 0.5s ease;
  .text-icon-icon {
    text-align: center;
    display: block;
    position:relative;

    height: 80px;

    .flaticon:before, .flaticon:after{
      font-size: 70px;
    }
    i{
      font-size: 40px !important;
      color: @brand-color;
      text-align: center;
    }
  }

  h3{
    margin: 30px 0 22px;
    font-size: 20px;
    text-transform: none;
    text-align: center;
    font-weight: 300;
  }


  .text-icon-body{
  //  overflow:hidden;
    min-height: 100px;
    text-align: center;

    p{
      color: #BBBBBB;
    }
  }
}

.divider-third .text-icon h3{
  color: #fff;
}