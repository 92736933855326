//.bx-wrapper img{
//  display:block;
//  max-width: 100%;
//}
//
//.bx-wrapper div{
//  display:block;
//  max-width: 100%;
//}

.homepage-slider{
  margin-top:-70px;
  z-index:1;
  position:relative;
}

@media(max-width: @screen-lg) {
  .homepage-slider{
    margin-top:0;
  }
}


.properties-slider-wrapper{
  height: 800px;
  overflow:hidden;
}

.property-slider-item{
  min-height: 800px;
 background-color: #fff;
  position:relative;

  .property-slider-info{
    width: 350px;
    padding: 20px;
    background: @brand-color;
    color: #fff;
    position:absolute;
    bottom: 50px;
    left:50%;
    margin-left: -175px;



    .property-slider-delim{
      margin:20px 0;
      height:1px;
      border-bottom: 1px solid rgba(255,255,255,.5);
      clear:both;
    }

    h3{
      font-size: 20px;
      color: #fff;
      text-decoration: none;
      margin-top:0;
      padding-top:0;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //  white-space: nowrap;
      margin-bottom: 10px;
      width: 100%;
      a{
        color: #fff;
        text-decoration: none;
      }
    }

    .property-box-meta-citem{
      color: #fff;
      i{
        color: #fff;
      }
    }

    .property-slider-price{
      font-size: 22px;
      color: #fff;
      font-weight: 500;
      float: right;
    }

    .property-slider-view{
      color: #fff;
      float: right;
    }
  }
}

@media(max-width: @screen-lg)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 650px;
  }
}

@media(max-width: @screen-md)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 550px;
  }
}

@media(max-width: @screen-sm)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 350px;
  }
}