.imagetext-module{
  margin:20px 0;
  background: #9d877a;
  padding:15px;

  .imagetext-module-svg{
   // max-height:80px;
    display:block;
    margin:10px auto 10px;
    color: #fff;
    svg{
      color: #fff;
      fill: #fff;
      max-height:80px;
      path{
        fill: #fff !important;
      }
    }
  }


  h3{
    text-align:center;
    font-weight: bold;
  }

  .imagetext-module-subtitle{
    text-align:center;
  }
}